<template>
  <div class="invalid-container">
    <img :src="require('@/assets/qietu/in.png')" alt="" />
    <p>临时链接已失效</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.invalid-container {
  position: relative;
  height: 700px;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 277px;
    height: 277px;
  }
  p {
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
}
</style>